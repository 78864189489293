import { assetUrlStringToVaultUrl } from "@holibob-packages/vault";

import { BrandForThemeFragment } from "../../apiHooks/graphql";
import { CSSFontFaceLoader } from "../../components/CSSFontFaceLoader";
import { FontCustomFragment } from "../../gql-request";
import { GoogleFontLoader } from "../../style/GoogleFontLoader";

export type BrandProps = {
    brand: BrandForThemeFragment;
};

export type BrandFontLoaderProps = BrandProps & {
    applyGlobalPrimaryFontStyle: boolean;
};

export function BrandFontLoader({ brand, applyGlobalPrimaryFontStyle }: BrandFontLoaderProps) {
    return (
        <>
            <BrandPrimaryFontLoader brand={brand} applyGlobalFontStyles={applyGlobalPrimaryFontStyle} />
            <BrandSpecialFontLoader brand={brand} />
        </>
    );
}

function BrandSpecialFontLoader({ brand }: BrandProps) {
    if (brand.specialFont?.__typename === "FontExternal") {
        return <GoogleFontLoader fontName={brand.specialFont.name} />;
    }
    if (brand.specialFont?.__typename === "FontCustom") {
        return <CustomFontLoader {...brand.specialFont} />;
    }
    return null;
}

function BrandPrimaryFontLoader({ brand, applyGlobalFontStyles }: BrandProps & { applyGlobalFontStyles: boolean }) {
    if (brand.primaryFont) {
        const style = `
            body {
                font-family: "${brand.primaryFont}", sans-serif;
            }
        `;
        return (
            <>
                <GoogleFontLoader fontName={brand.primaryFont} />
                {applyGlobalFontStyles && (
                    <style jsx global>
                        {style}
                    </style>
                )}
            </>
        );
    }
    return null;
}

export type CustomFontUrlOptions = {
    regular: string;
    medium?: string;
    bold?: string;
    regularItalic?: string;
    mediumItalic?: string;
    boldItalic?: string;
};

function computeFontUrl(assetUrlString: string | undefined, fallbackUrl: string) {
    const isFallback = !assetUrlString;
    return { url: isFallback ? fallbackUrl : assetUrlStringToVaultUrl(assetUrlString).unwrap().toString(), isFallback };
}

export function resolveCustomFontUrls({
    regular,
    medium,
    bold,
    regularItalic,
    mediumItalic,
    boldItalic,
}: CustomFontUrlOptions) {
    const regularFontUrl = assetUrlStringToVaultUrl(regular).unwrap().toString();
    const { url: mediumFontUrl, isFallback: isFallbackMedium } = computeFontUrl(medium, regularFontUrl);
    const { url: boldFontUrl, isFallback: isFallbackBold } = computeFontUrl(bold, mediumFontUrl);
    const { url: regularItalicFontUrl, isFallback: isFallbackRegularItalic } = computeFontUrl(
        regularItalic,
        regularFontUrl
    );
    const { url: mediumItalicFontUrl, isFallback: isFallbackMediumItalic } = computeFontUrl(
        mediumItalic,
        regularItalicFontUrl
    );
    const { url: boldItalicFontUrl, isFallback: isFallbackBoldItalic } = computeFontUrl(
        boldItalic,
        mediumItalicFontUrl
    );

    return {
        regular: regularFontUrl,
        medium: mediumFontUrl,
        isFallbackMedium,
        bold: boldFontUrl,
        isFallbackBold,
        regularItalic: regularItalicFontUrl,
        isFallbackRegularItalic,
        mediumItalic: mediumItalicFontUrl,
        isFallbackMediumItalic,
        boldItalic: boldItalicFontUrl,
        isFallbackBoldItalic,
    } as const;
}

export const CUSTOM_FONT_NAME = "HolibobSpecial";

function CustomFontLoader({ regular, medium, bold, regularItalic, mediumItalic, boldItalic }: FontCustomFragment) {
    const urls = resolveCustomFontUrls({
        regular: regular.uri,
        medium: medium?.uri,
        bold: bold?.uri,
        regularItalic: regularItalic?.uri,
        mediumItalic: mediumItalic?.uri,
        boldItalic: boldItalic?.uri,
    });
    return (
        <>
            <CSSFontFaceLoader fontName={CUSTOM_FONT_NAME} fontStyle="normal" fontWeight={400} url={urls.regular} />
            <CSSFontFaceLoader fontName={CUSTOM_FONT_NAME} fontStyle="normal" fontWeight={500} url={urls.medium} />
            <CSSFontFaceLoader fontName={CUSTOM_FONT_NAME} fontStyle="normal" fontWeight={600} url={urls.bold} />
            <CSSFontFaceLoader
                fontName={CUSTOM_FONT_NAME}
                fontStyle="italic"
                fontWeight={400}
                url={urls.regularItalic}
            />
            <CSSFontFaceLoader
                fontName={CUSTOM_FONT_NAME}
                fontStyle="italic"
                fontWeight={500}
                url={urls.mediumItalic}
            />
            <CSSFontFaceLoader fontName={CUSTOM_FONT_NAME} fontStyle="italic" fontWeight={600} url={urls.boldItalic} />
        </>
    );
}
